"use client";
// import { partnerData } from "@/data/Data";
import { LOAD_PARTNER } from "@/services/api/api_constance";
import { callApi } from "@/services/api/base_api";
import Partner from "@/types/Partner";
import ServerDataProp from "@/types/ServerDataProp";
import { useEffect, useState } from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CustomGsapHeaderAnimate from "../utils/CustomGsapHeaderAnimate";
import DivAnimateYAxis from "../utils/DivAnimateYAxis";

const PartnerSection:React.FC<ServerDataProp> = ({setting}) => {
  const [partnerData, setPartnerData] = useState<Partner[]>([]);
  useEffect(() => {
    callApi.get(LOAD_PARTNER).then(res => {
      setPartnerData(res.data);
    })
  }, [])
  return (
    <section className="rv-12-partner pt-80 pb-20 mb-40">
      <div className="container">
        <div className="rv-7-section__heading">

          <CustomGsapHeaderAnimate>
            <h2 className="rv-7-section__title rv-text-anime" >
              Đối tác của chúng tôi
            </h2>
          </CustomGsapHeaderAnimate>
          <CustomGsapHeaderAnimate>
            <h6 className="rv-7-section__sub-title rv-text-anime" >
              Danh sách và giới thiệu các đối tác – khách hàng chiến lược của XanhEco
            </h6>
          </CustomGsapHeaderAnimate>
        </div>

        <DivAnimateYAxis className="rv-12-partners rv-section-spacing" style={{padding:"0 0 60px"}}>
          <div className="container">
            <Swiper
              className="rv-12-partners__slider"
              autoplay={true}
              spaceBetween={27}
              slidesPerView={5}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 3,
                },
                576: {
                  slidesPerView: 4,
                },
                768: {
                  spaceBetween: 47,
                  slidesPerView: 4,
                },
                992: {
                  spaceBetween: 47,
                  slidesPerView: 5,
                },
                1200: {
                  spaceBetween: 47,
                  slidesPerView: 5,
                },
                1400: {
                  spaceBetween: 47,
                  slidesPerView: 5,
                },
              }}
              modules={[Autoplay]}
            >
              {partnerData.map((partner, index) => (
                <SwiperSlide key={index}>
                  <img src={partner.imageUrl} alt={partner.name} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </DivAnimateYAxis>
      </div>
    </section>
  );
};

export default PartnerSection;
