"use client";
import { LOAD_PRODUCT } from "@/services/api/api_constance";
import { callApi } from "@/services/api/base_api";
import { ShopItem } from "@/types";
import Product, { productToShopItem } from "@/types/Prouct";
import ServerDataProp from "@/types/ServerDataProp";
import { useEffect, useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ShopCard from "../shop/ShopCard";
import CustomGsapHeaderAnimate from "../utils/CustomGsapHeaderAnimate";
import DivAnimateYAxis from "../utils/DivAnimateYAxis";

const ProductSection: React.FC<ServerDataProp> = ({setting}) => {
  const [productData, setProductData] = useState<Product[]>([]);
  useEffect(() => {
    callApi.get(LOAD_PRODUCT+'?sortby=top').then(res => {
      setProductData(res.data);
    })
  }, [])
  return (
    <section className="rv-12-products rv-section-spacing">
      <div className="container">
        <div className="rv-7-section__heading">
          <CustomGsapHeaderAnimate>
            <h6 className="rv-7-section__sub-title rv-text-anime">
              Sản phẩm của chúng tôi
            </h6>
          </CustomGsapHeaderAnimate>
          <CustomGsapHeaderAnimate>
            <h2 className="rv-7-section__title rv-text-anime">
              Sản phẩm bán chạy nhất.
            </h2>
          </CustomGsapHeaderAnimate>
        </div>
        <DivAnimateYAxis>
          <Swiper
            className="rv-7-products__slider"
            spaceBetween={15}
            slidesPerView={4}
            navigation={{
              prevEl: "#rv-7-products__slider-nav .prev",
              nextEl: "#rv-7-products__slider-nav .next",
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              480: {
                centeredSlides: true,
                loop: true,
                slidesPerView: 1.6,
              },
              576: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2.3,
                centeredSlides: true,
                loop: true,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1200: {
                spaceBetween: 20,
                slidesPerView: 4,
              },
              1400: {
                spaceBetween: 30,
              },
            }}
            modules={[Navigation]}
          >
            {productData.slice(0, 5).map((item, index) => {
              const shopItem: ShopItem = productToShopItem(item);
              return (
                <SwiperSlide key={item._id}>
                  <ShopCard
                    
                    product={shopItem}
                  />
                </SwiperSlide>
              )
            })
            }

            <div
              className="rv-1-slider-nav rv-12-slider-nav"
              id="rv-7-products__slider-nav"
            >
              <button className="prev">
                <i className="fa-regular fa-arrow-left"></i>
              </button>
              <button className="next">
                <i className="fa-regular fa-arrow-right"></i>
              </button>
            </div>
          </Swiper>
        </DivAnimateYAxis>
      </div>
    </section>
  );
};

export default ProductSection;
