import { ShopItem } from ".";
import VideoInfo from "./VideoInfo";

interface ProductVariant{
    variantName: string;
    slug: string;
}

interface Product{
    _id: string;
    slug: string;
    name:string;
    code: string;
    desc?: string;
    price: number;
    prevPrice:number;
    imageUrl: string;
    popularity: number;
    discount: boolean;
    shortTitle?: string;
    ingredient: string[];
    attribute: string[];
    outstanding: string[];
    color: string;
    rating: number;
    category: string;
    variants?:ProductVariant[];
    images?:string[];
    variantName?:string;
    video?: VideoInfo
}
///hàm chuyển đổi đối tượng sản phẩm sang đối tượng mua hàng
export function productToShopItem(product:Product): ShopItem{
    return {
        id:0,
        _id: product._id,
        name:product.name,
        category:product.category,
        color:product.color,
        code:product.code,
        variantName: product.variantName,
        quantity:1,
        price: product.price,
        prevPrice: product.prevPrice,
        popularity: product.popularity,
        discount: product.discount,
        slug: product.slug,
        rating: product.rating,
        desc: product.desc,
        img: product.imageUrl
    } as ShopItem
}
export default Product;