"use client";
import { LOAD_FEATURE } from "@/services/api/api_constance";
import { callApi } from "@/services/api/base_api";
import Feature from "@/types/Feature";
import ServerDataProp from "@/types/ServerDataProp";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useState } from "react";

// Make sure to register ScrollTrigger
gsap.registerPlugin(ScrollTrigger);
const InfoSection: React.FC<ServerDataProp> = ({ setting }) => {

  const [featureData, setFeatureData] = useState<Feature[]>([]);
  useEffect(() => {
    callApi.get(LOAD_FEATURE).then(res => {
      setFeatureData(res.data);
    })
  }, [])
  useEffect(() => {
    // GSAP animation for rv-12-infos-vectors
    gsap.to("#rv-12-infos-vectors", {
      x: -400,
      duration: 0.1,
      ease: "Linear.easeNone",
      scrollTrigger: {
        trigger: "#rv-12-infos-vectors",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });

    // GSAP animation for rv-12-infos-vectors-2
    gsap.to("#rv-12-infos-vectors-2", {
      x: 250,
      duration: 0.1,
      ease: "Linear.easeNone",
      scrollTrigger: {
        trigger: "#rv-12-infos-vectors-2",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });
  }, []);
  return (
    <section className="rv-12-infos pt-160 pb-160">
      <div className="container">
        <div className="row align-items-center rv-12-infos__row rv-9-guides__row justify-content-evenly">
          <div className="col-xl-4 col-lg-5 col-6 col-xxs-12">
            {
              Array.isArray(featureData) && featureData.slice(0, Math.floor(featureData.length / 2)).map((item, index) => <div className="rv-8-service rv-9-guide rv-12-info" key={index}>
                <div className="rv-9-service__icon">
                  <img src={item.iconUrl} alt={item.name} />
                </div>

                <div className="rv-9-guide__txt">
                  <h4 className="rv-3-service__title">
                    <a href="#">{item.name}</a>
                  </h4>
                  <p className="rv-3-service__descr">
                    {item.desc}
                  </p>
                </div>
              </div>)
            }
          </div>

          <div className="col-xl-4 d-none d-xl-block">
            <div className="rv-9-guides__img text-center">
              <img src={setting?.img1} alt={setting?.name} />
            </div>
          </div>

          <div className="col-xl-4 col-lg-5 col-6 col-xxs-12">
            {
              Array.isArray(featureData) && featureData.slice(Math.ceil(featureData.length / 2), featureData.length).map((item, index) => <div className="rv-8-service rv-9-guide rv-12-info" key={index}>
                <div className="rv-9-service__icon">
                  <img src={item.iconUrl} alt={item.name} />
                </div>

                <div className="rv-9-guide__txt">
                  <h4 className="rv-3-service__title">
                    <a href="#">{item.name}</a>
                  </h4>
                  <p className="rv-3-service__descr">
                    {item.desc}
                  </p>
                </div>
              </div>)
            }

          </div>
        </div>
      </div>

      <div className="rv-12-infos__vectors" id="rv-12-infos-vectors">
        <img
          src="/assets/img/rv-12-infos-vector.png"
          alt="vector"
          className="rv-12-infos-vector"
        />
        <img
          src="/assets/img/rv-12-infos-vector.png"
          alt="vector"
          className="rv-12-infos-vector"
        />
      </div>
      <div
        className="rv-12-infos__vectors rv-12-infos__vectors--2"
        id="rv-12-infos-vectors-2"
      >
        <img
          src="/assets/img/rv-12-infos-vector.png"
          alt="vector"
          className="rv-12-infos-vector"
        />
        <img
          src="/assets/img/rv-12-infos-vector.png"
          alt="vector"
          className="rv-12-infos-vector"
        />
      </div>
    </section>
  );
};

export default InfoSection;
