"use client";
import { LOAD_GALLERY } from "@/services/api/api_constance";
import { callApi } from "@/services/api/base_api";
import Gallery from "@/types/Gallery";
import ServerDataProp from "@/types/ServerDataProp";
import { useEffect, useState } from "react";
import DivAnimateYAxis from "../utils/DivAnimateYAxis";

const GallerySection: React.FC<ServerDataProp> = ({ setting }) => {
  const [galleryData, setGalleryData] = useState<Gallery[]>([]);
  useEffect(() => {
    callApi.get(LOAD_GALLERY).then(res => {
      setGalleryData(res.data);
    })
  }, [])
  return (
    <div className="mb-80">
      <DivAnimateYAxis className="rv-12-gallery" position={30}>
        <div className="row g-4 row-cols-xl-5 row-cols-md-3 row-cols-2 row-cols-xxs-1 justify-content-center">
          {galleryData?.map((img, index) => <div key={index} className="col">
            <div className="rv-5-gallery__img rv-12-gallery__img">
              <button type="button">
                <i className="fa-brands fa-instagram"></i>
              </button>
              <img src={img.imageUrl} alt={img.title} />
            </div>
          </div>)}

        </div>
      </DivAnimateYAxis>
    </div>
  );
};

export default GallerySection;
