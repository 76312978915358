"use client";
import { addToCart } from "@/redux/features/cartSlice";
import { addToWishlist } from "@/redux/features/wishlistSlice";
import { useAppDispatch } from "@/redux/hooks";
import { formatCurrency } from "@/services/tool/Currency";
import { navigate } from "@/services/ui/useRedirect";
import { ShopItem } from "@/types";
import Link from "next/link";
import { toast } from "react-toastify";
type Props = {
  product: ShopItem;
  style?: string;
};

const ShopCard = ({
  product,
  style
}: Props) => {
  const dispatch = useAppDispatch();
  const handleAddToCart = () => {
    dispatch(addToCart(product));
    toast.success("Thêm sản phẩm vào giỏ hàng thành công!");
  };

  const handleAddToWishlist = () => {
    dispatch(addToWishlist(product));
    toast.success("Đã thêm sản phẩm vào danh sách yêu thích!");
  };

  return (
    <div className={`rv-3-product rv-12-product ${style ? style : ""}`}>
      <div className="rv-3-product__img rv-12-product__img">
        <img src={product.img} alt="Product Image" />
        {product.discount && <span className="rv-3-product__tag">{Math.round((product.price - product.prevPrice) * 100 / product.prevPrice)}%</span>}
        <div className="rv-3-product__actions">
          <button className="quick-view" onClick={()=>{
            navigate(`/shop/${encodeURIComponent(product.slug)}`)
          }}>
            <i className="fa-regular fa-eye"></i>
          </button>
          <button className="add-to-wishlist" onClick={handleAddToWishlist}>
            <i className="fa-regular fa-heart"></i>
          </button>
          <button className="compare" onClick={handleAddToCart}>
            <i className="fa-regular fa-cart-shopping"></i>
          </button>
        </div>
      </div>

      <div className="rv-3-product__txt">
        <div className="rv-3-product__rating">
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-regular fa-star"></i>
        </div>
        <h5 className="rv-3-product__title">
          <Link href={`/shop/${product.slug}`}>{product.name} ({product.code})</Link>
        </h5>

        <p className="rv-inner-product__descr">
          {product.desc}
        </p>

        <div className="rv-3-product__bottom">
          <span className="rv-3-product__price">
            {
              product.discount && <span className="prev-price">{formatCurrency(product.prevPrice)}</span>
            }
            <span className="current-price">{formatCurrency(product.price)}</span>
          </span>

          <button className="rv-3-product__cart-btn" onClick={handleAddToCart}>
            <img src="/assets/add-to-cart.svg" alt="add to cart" />
            Thêm vào giỏ hàng
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShopCard;
